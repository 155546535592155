import {Component, OnInit} from '@angular/core';

import {Event} from '../event';
import {EventService} from '../event.service';

import * as L from 'leaflet';
import {StructDepService} from '../struct-dep.service';
import * as eutil from './event_util';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'app-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.css']
})
export class MainMapComponent implements OnInit {
  private map;

  lastLines = [];
  lastMarkers = [];
  events = {};
  activeEvents: number[];
  activeEventID: Subject<number>;
  currentDay: BehaviorSubject<Date>;

  selectedEvent: Event;

  structDeps = {};
  lastStructDep: number;


  constructor(private eventService: EventService, private structDepService: StructDepService) {
  }

  ngOnInit(): void {
    this.initMap();

    this.getEvents();
    this.getStructDeps();

    this.activeEventID = new Subject<number>();
    this.currentDay = new BehaviorSubject<Date>(new Date(2017, 11, 16));

    this.activeEvents = [];
    this.activeEventID.subscribe(this.changeEvent.bind(this));
  }

  getEvents(): void {
    this.eventService.getEvents()
      .subscribe(events => {
        this.parseEvents(events);
      });
  }

  getStructDeps(): void {
    this.structDepService.getStructDeps().subscribe(structDeps => {
      for (const sd of structDeps) {
        const group = sd.groupId;
        if (! this.structDeps.hasOwnProperty(group)) {
          this.structDeps[group] = [];
        }

        const points = [];
        for (const p of sd.path) {
            points.push([p[1], p[0]]);
        }
        const line = L.polyline(points, {group: group}).on('click', this.structDepClick.bind(this));
        line.addTo(this.map);
        this.structDeps[group].push(line);

      }
    });
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [52.3224346555334, 9.81649473554199],
      zoom: 12
    });

    const at = 'pk.eyJ1Ijoibmljb2xhc3RlbXBlbG1laWVyIiwiYSI6ImNrYnJ4djlvYTMwcXgzMHBqNTRlMm81b24ifQ.WwPgH3YN2j6tyGLgGQwwXQ';
   // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    const tiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token='
      + at, {
      maxZoom: 19,
      attribution: '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);
  }


  private parseEvents(events: Event[]): void {
    for (const e of events) {
      this.events[e.id] = e;
    }
    this.currentDay.subscribe(d => this.updateDay(d));
  }


  private updateDay(d): void {

    const iconZoom = 1 / 8;
    const iconWidth = 75 * eutil.getEMValue() * iconZoom;
    const iconHeight = 19 * eutil.getEMValue() * iconZoom;



    this.removeMarkers(this.lastMarkers);
    this.lastMarkers = [];
    for (const key in this.events) {
      const ev = this.events[key];

      const year = ev.start.substr(0, 4);
      const month = ev.start.substr(5, 2);
      const day = ev.start.substr(8, 2);
      const evDate = new Date(year, month, day);
      if (evDate.getTime() === d.getTime()) {
        const coordParts = ev.coordinates.split(',');
        const lat = parseFloat(coordParts[0]);
        const lon = parseFloat(coordParts[1]);
        const icon = new L.icon({
          html: '<img src="' + eutil.getMarkerImage(ev) + '" class="marker-image">',
          iconUrl : eutil.getMarkerImage(ev),
          className: 'event-marker',
          iconSize: [iconWidth, iconHeight],
          iconAnchor: [iconWidth / 2, iconHeight]
        });
        icon.options.shadowSize = [0, 0];
        const mark = L.marker([lon, lat], {id: ev.id, icon}).on('click', this.eventClick.bind(this));
        this.lastMarkers.push(mark);
        mark.addTo(this.map);
        const circle = L.circle([lon, lat], ev.impact * 1000 , {fillOpacity: 0, color: eutil.getEventColor(ev)});
        circle.addTo(this.map);
        this.lastMarkers.push(circle);
      }
    }
    this.activeEventID.next(undefined);

    for (const k in this.structDeps) {
      for (const sd of this.structDeps[k]) {
        sd.bringToFront();
      }
    }

  }

  private removeMarkers(markers): void {
    for (const m of markers) {
      m.remove(this.map);
    }
  }

  private eventClick(e): void {
    const id = e.target.options.id;
    this.activeEventID.next(id);
  }

  private structDepClick(sd): void {
    if (this.lastStructDep){
      for (const l of this.structDeps[this.lastStructDep]) {
        l.setStyle({
          color: 'blue'
        });
      }
    }

    const group = sd.target.options.group;
    for (const l of this.structDeps[group]) {
      l.setStyle({
        color: '#ba000d'
      });
    }
    this.lastStructDep = group;
  }

  private changeEvent(eventId): void {
    this.removeGraph(this.lastLines);
    if (eventId) {
      const e = this.events[eventId];
      this.lastLines = this.plotGraph(e);
      this.selectedEvent = e;

    } else {
      this.lastLines = [];
      this.selectedEvent = undefined;
    }
  }

  private createGraph(streets: string[]): any {
    const lineStrings = [];
    for (let s of streets) {
      s = s.substr(11, s.length - 11 - 1);
      const pointStrings = s.split(',');

      const lsPoints = [];
      for (const ps of pointStrings) {
        const coordParts = ps.split(' ');
        const lat = parseFloat(coordParts[0]);
        const lon = parseFloat(coordParts[1]);

        lsPoints.push([lon, lat]);
      }
      const ls = L.polyline(lsPoints);
      lineStrings.push(ls);
    }
    return lineStrings;
  }

  private plotGraph(e): any {
    const affectedGraph = this.createGraph(e.affected_subgraph);
    let targetColor;
    if (e.historical) {
      targetColor = eutil.getEventColor(e);
    } else {
      targetColor = '#FF8F00';
    }

    for (const ls of affectedGraph) {

      ls.setStyle({
        color: targetColor,
      });
      ls.addTo(this.map);
    }
    return affectedGraph;
  }

  private removeGraph(graph): void {
    for (const ls of graph) {
      ls.remove(this.map);
    }
  }
  public decrementDay(): void {
    const d = this.currentDay.getValue();
    d.setDate(d.getDate() - 1);
    this.currentDay.next(d);
  }

  public incrementDay(): void {
    const d = this.currentDay.getValue();
    d.setDate(d.getDate() + 1);
    this.currentDay.next(d);
  }

}
