import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {StructuralDependency} from './structuralDependency';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StructDepService {

  constructor(private http: HttpClient) { }

  getStructDeps(): Observable<StructuralDependency[]> {
    return this.http.get<StructuralDependency[]>('api/structDeps');
  }
}
