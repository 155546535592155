import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject, BehaviorSubject } from 'rxjs';

import {Event} from './event';
import {catchError} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EventService  {


  constructor(private http: HttpClient) {
  }

  getEvents(): Observable<Event[]> {
    return this.http.get<Event[]>('api/events');
  }




}
