<div id = "bar">
  <div id="timeSelector">
    <button class="btn" id="decrementDay" (click)="decrementDay()"><i class="fas fa-caret-left"></i></button>
    <span id="currentDay">{{currentDayString}}</span>
    <button class="btn" id="incrementDay" (click)="incrementDay()"><i class="fas fa-caret-right"></i></button>
  </div>
  <div id="imprint">
    <a href="https://www.l3s.de/en/imprint">Impressum/Imprint</a>
  </div>
</div>
