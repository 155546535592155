import { Event } from '../event';


export function  getMarkerImage(event: Event): string {
  let result = 'assets/';
  switch (event.category) {
    case 'comedy':
      result += 'comedy.png';
      break;
    case 'fair':
      result += 'fair.png';
      break;
    case 'concert':
      result += 'concert.png';
      break;
    case 'football':
      result += 'soccer.png';
      break;
    case 'show':
      result += 'show.png';
      break;
    case 'party':
      result += 'party.png';
      break;
    case 'other':
    default:
      result += 'other.png';
      break;
  }
  return result;
}


export function getEMValue(): number{
  let em;
  const div = document.getElementById('em-div');
  div.style.height = '1em';
  return ( em = div.offsetHeight );
}

export function getEventColor(event: Event): string {
    switch (event.category) {
      case 'fair':
        return '#4dd2b4';
      case 'party':
        return '#dc488c';
      case 'concert':
        return '#134a97';
      case 'football':
        return '#416f0c';
      case 'show':
        return '#b82d8b';
      case 'comedy':
        return '#4995c8';
      case 'other':
      default:
        return '#000000';
    }
}
