import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import {Observable} from 'rxjs';


@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.css']
})
export class TimeSelectorComponent implements OnInit {
  @Output() decrementEvent = new EventEmitter<void>();
  @Output() incrementEvent = new EventEmitter<void>();

  currentDayString: string;
  @Input() currentDay: Observable<Date>;

  constructor() { }

  ngOnInit(): void {
    this.currentDay.subscribe(d => {
      this.currentDayString = d.toLocaleDateString();
    });
  }

  decrementDay(): void {
    this.decrementEvent.emit();
  }

  incrementDay(): void{
    this.incrementEvent.emit();
  }
}
