import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { GoogleChartsModule } from 'angular-google-charts';


import { AppComponent } from './app.component';
import { MainMapComponent } from './main-map/main-map.component';

import { InMemoryDataService} from './in-memory-data.service';
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { EventDetailComponent } from './event-detail/event-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    MainMapComponent,
    TimeSelectorComponent,
    EventDetailComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {dataEncapsulation: false}),
    GoogleChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
