<div *ngIf="event"  id="eventDetails">
  <span id="eventName">{{event.name}}</span>
  <span id="eventVenue">{{event.place}}</span>
  <span id="eventStart">Start Time: {{event.start | slice:11 : 16}}</span>
  <span *ngIf=event.historical>Past Event</span>
  <span *ngIf="event.historical != true" style="color: #FF8F00">Upcoming Event</span>
  <google-chart id="chart"
    [type]="chartType"
    [data]="data"
    [options]="options"
  [dynamicResize]="true">
  </google-chart>

  <div id="tester"></div>
</div>

