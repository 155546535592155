import {Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange} from '@angular/core';

import {Event} from '../event';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit, OnChanges {
  @Input() event: Event;

  chartType = 'LineChart';
  data = [];
  options = {
    hAxis: {
      title: 'Time [minutes]',
      gridlines: {
        count: 10
      },
      textStyle: {
        fontSize: 14
      },
      titleTextStyle: {
        fontSize: 14,
        italic: false
      }
    },
    vAxis: {
      title: 'Temporal Impact',
    textStyle: {
      fontSize: 14
      },
      titleTextStyle: {
        fontSize: 14,
        italic: false
      }
    },
    legend : {
      position: 'none'
    },
    titleTextStyle: {
      fontSize: 0
    },
    chartArea: {
      width: '80%',
    }
  };


  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentEvent: Event = changes.event.currentValue;
    if (!currentEvent) {
      return;
    }
    this.data = [];
    for (const record of currentEvent.temporal_impacts) {
      this.data.push([record.minute, record.temporal_impact]);
    }
  }

}
