import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';
import {Event} from './event';

import events from '../assets/events_out.json';
import structDeps from '../assets/structDep_out.json';

@Injectable({
  providedIn: 'root'
})
export class InMemoryDataService implements InMemoryDbService {

  constructor() { }

  createDb() {
    return {events, structDeps};
  }
}
