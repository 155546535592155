<app-time-selector
  (decrementEvent)="decrementDay()"
  (incrementEvent)="incrementDay()"
  [currentDay]="currentDay"
></app-time-selector>

<div class="map-container">
  <div class="map-frame">
    <div id="map"></div>
  </div>
</div>
<app-event-detail [event]="selectedEvent" ></app-event-detail>
<div id="em-div" style="height:0;width:0;outline:none;border:none;padding:none;margin:none;box-sizing:content-box;"></div>
